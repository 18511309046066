import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Query, User } from '../types';
import { getQueryParams, getTotal, getErrorMessage } from '../utils/query-helpers';

const GET_USERS = gql`
  query usersPaginateQuery(
    $first: Int
    $offset: Int
    $orderBy: [_UserOrdering]
    $filter: _UserFilter
  ) {
    User(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
      _id
      name
      avgStars
      numReviews
    }
  }
`;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styles: []
})
export class UsersComponent implements OnInit {
  error = '';
  loading = false;
  pageSize: number;
  users: Observable<User[]>;
  total = 0;

  constructor(private apollo: Apollo) { }

  ngOnInit() {
    this.loading = true;
  }

  

}
