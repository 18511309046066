import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Query, Business } from '../types';
import { getQueryParams, getTotal, getErrorMessage } from '../utils/query-helpers';

const GET_BUSINESSES = gql`
  query businessesPaginateQuery(
    $first: Int
    $offset: Int
    $orderBy: [_BusinessOrdering]
    $filter: _BusinessFilter
  ) {
    Business(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
      _id
      name
      address
      city
      state
      avgStars
    }
  }
`;

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styles: []
})
export class BusinessesComponent implements OnInit {

  businesses: Observable<Business[]>;
  error = '';
  loading = false;
  total = 0;

  constructor(private apollo: Apollo) { }

  ngOnInit() {
    this.loading = true;
  }



}
